.apyCard {
    min-width: 275px;
    max-width: 400px;
    padding: 20px;
    margin: 20px;
}

.infoBox {
    border: 1px solid black;
    padding: 5px;
}

.bold {
    font-weight: bold;
}

.centerText {
    justify-content: center;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.sectionInfo {
    font-weight: bold;
    align-self: center;
    margin-top: 10px;
}

.apyLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}