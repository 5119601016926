@import url('https://fonts.googleapis.com/css?family=Open+Sans'); 

.center {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.body {
  font-family: 'Open Sans';
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}
